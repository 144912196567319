:root {
    --color--primary: rgba(255, 251, 254, 1);
    --color--secondary: rgba(22, 18, 18, 1);
    --logo-theme--color: rgb(11,142,54, 1);
    --font-family--primary: 'Poppins';
    --font-family--secondary: 'Oranienbaum';
    --font-size__h1--mobile: 3.2rem;
    --font-size__h1--desktop: 4.8rem;
    --font-size__h2: 2.4rem;
    --font-size--base: 1.6rem;
    --font-size__p--mobile: 1.4rem;
    --font-size__p--desktop: 2rem;
    --font-size__a--mobile: 3.2rem;
    --font-size__a--desktop: 3rem;
    --font-weight--normal: 400;
    --navigation--height: 9rem;
    --input-width--desktop: 40rem;
    --textarea-width--desktop: 40rem;
    --space--xs: 0.5rem;
    --space--s: 1rem;
    --space--m: 1.5rem;
    --space--l: 2rem;
    --space--xl: 2.5rem;
    --space--xxl: 3rem;
}
/* 62.5% 1rem=10px */
html {
    font-size: 62.5%;
    font-family: var(--font-family--primary), Arial, Helvetica, sans-serif;
}

*   {
    box-sizing: border-box;
    margin: 0;
}

h1 {
    text-align: center;
    font-weight: var(--font-weight--normal);
    color: var(--color--primary);
}

h3 {
    font-weight: var(--font-weight--normal);
}

a {
    text-decoration:none;
    font-style: normal;
}

a:hover,
a:focus,
a:active,
.address--links:hover {
    color: var(--logo-theme--color);
    cursor: pointer;
}

a:focus {
    text-decoration: underline;
}

li::marker {
    color: var(--logo-theme--color);
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .main--router {
        background-image: url(/static/media/mobile-background.3425e014.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    h1 {
        font-size: var(--font-size__h1--mobile);
        padding-bottom:var(--space--xxl);
    }

    h2 {
        font-size: var(--font-size__h2);
    }

    p {
        font-size: var(--font-size__p--mobile);
    }

    li {
        font-size: var(--font-size__p--mobile);
    }

    a {
       font-size: var(--font-size__a--mobile);
       color: var(--color--secondary);
    }

    input {
        width: 100%;   
    }
    
    textarea {
        width: 100%;
    }
}


/* Desktop and tablet */
@media only screen and (min-width: 769px) {
    .main--router {
        background-image: url(/static/media/roof-opacity-desktop.e453c439.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    h1 {
        font-size: var(--font-size__h1--desktop);
        padding-top: 8rem;
        padding-bottom: 8rem;
    }

    h2 {
        font-size: var(--font-size__h2);
    }

    p {
        font-size: var(--font-size__p--desktop);
    }

    li {
        font-size: var(--font-size__p--desktop);
    }

    a {
        font-size: var(--font-size__a--desktop);
        color: var(--color--secondary);
    }

    input {
        width: var(--input-width--desktop);
    }

    textarea {
        width: var(--textarea-width--desktop);
    }


}
#home,
#offer {
    padding-left: var(--space--m);
    padding-right: var(--space--m);
}

#home,
#offer {
    padding-bottom: 5rem;
}

.contact {
    padding-bottom: 10rem;
    padding-top: 10rem;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .contact {
        padding-top: var(--space--xxl);
    }
}

/* Desktop and tablet */
@media only screen and (min-width: 769px) {
    .contact {
        padding-top: 0;
    }
}
.header {
    position: -webkit-sticky;
    position: sticky;
    z-index: 3;
    top: 0;
    background-color: var(--color--primary);
}

.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

body {
    position: relative;
}

/* Mobile */
@media only screen and (max-width: 768px) {    
    .navigation {
        height: var(--navigation--height);
        padding-left: var(--space--xs);
        padding-right: var(--space--xl);
    }

    .menu__links {
        display: none; 
    }

    .logo {
        height: 8rem;
        width: 10rem;
        margin-top: var(--space--s);
    }

    .menu__icon {
        height: 5.5rem;
        width: 5.5rem;
    }

    .icon--close {
        height: 0.1rem;
        width: 0.1rem;
        opacity: 0;
    }

    .icon--active {
        height: 5rem;
        width: auto;
        opacity: 1;
    }

    .icon--inactive {
        display: none;
    }

    .menu-hidden,
    .menu-toggled main,
    .menu-toggled footer {
        width: 0.1rem;
        height: 0.1rem;
        opacity: 0;
        overflow: hidden;
        transition-property: opacity;
        transition-duration: 0.3s;
    }

    .menu-active {
        width: 100vw;
        height: calc(100vh - var(--navigation--height));
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-image: url(/static/media/hidden-menu-background.2f767b33.webp);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        position: absolute;  
        z-index: 3;
        right: 0;
        top: var(--navigation--height);
        overflow: hidden;
    }

    .menu-active a {
        color: var(--color--primary);
    }
}   


/* Desktop and tablet */
@media only screen and (min-width: 769px) {
    .menu-hidden,
    .menu__icon,
    .icon--close {
        display: none;
    }

    .logo {
        margin-left:var(--space--l);
    }

    .menu__links a {
        padding:var(--space--xl);
        font-size: 2.8rem;
    }

    .menu__links a:last-of-type {
        margin-right:var(--space--xl);
    }
}

/* Desktop and tablet */
@media only screen and (max-width: 1000px) {
    .menu__links a {
        font-size: 2rem;
    }
}
.about-us {
    padding-top: var(--space--l);
    padding-bottom: var(--space--xl);
    padding-left: var(--space--l);
    padding-right: var(--space--l);
}

.main--container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .main-page__picture {
        display: none;
    }

    .about-us {
        background-color: var(--color--primary);
        border-radius: 1rem;
    }

    .about-us p, 
    .about-us li {
        font-size: var(--font-size__p--mobile);
    }

    .main-page h1 {
        padding-top: var(--space--xxl);
    }

    ul {
        padding-left: var(--space--m);
    }
}


/* Desktop and tablet */
@media only screen and (min-width: 769px) {
    .main--router {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .main-page {
        margin-bottom: 13rem;
        width: 81%;
    }
    
    .main-page__picture {
        margin-top: 5rem;
        border-radius: 1%;
    }
    
    .about-us {
        background-color: var(--color--primary);
        flex-wrap: wrap;
        grid-gap: 10rem;
        gap: 10rem;
        justify-content: center;
        display: flex;
        padding-top: 5rem;
        padding-bottom: 5rem;
        border-radius: 2rem;
    }

    .text--information {
        width: 65rem;
        padding-top: 4rem;
        padding-bottom: 4rem;
    }

    ul {
        padding-left: var(--space--l);
    }
}

/* Desktop and tablet */
@media only screen and (max-width: 1695px) {
    .main-page__picture {
        margin-top: -7rem;
    }
}

/* Desktop and tablet */
@media only screen and (max-width: 1000px) {
    .main-page__picture {
        width: 40rem;
        height: 50.6rem;
    }
}
.offer__tiles > article:hover {
    background-color: #EEF2E6;
    box-shadow: 0.1rem 0.3rem 0.5rem rgba(0, 0, 0, 0.9);
    scale: 1.05;
    
}


#offer div img {
    width: 7rem;
    height: 7rem;
    border-radius: 1rem;
}

.material--wood,
.material--tile,
.material--tinware,
.material--mug,
.material--flashings,
.material--windows {
    background-color: var(--color--primary);
    padding-top: var(--space--l);
    padding-bottom: var(--space--xl);
    padding-left: var(--space--l);
    padding-right: var(--space--xl);
    border-radius: 1rem;
}

.tile__title {
    display: flex;
    align-items: center;
    padding-bottom: var(--space--s);
}

#offer div > div > p {
    padding-left: var(--space--xs);
}

.tile__title h2 {
    font-weight: var(--font-weight--normal);
    padding-left: var(--space--m);
}

ul {
    margin-left: var(--space--xs);
}

/* Mobile */
@media only screen and (max-width: 768px) {
    #offer {
    display: grid;
    place-items: center;
    padding-top: var(--space--xxl);
    padding-bottom: 10rem;
    padding-left: var(--space--m);
    padding-right: var(--space--m);
    }

    .material--wood,
    .material--tile,
    .material--tinware,
    .material--mug,
    .material--flashings,
    .material--windows {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        line-height: 2.5rem;
        margin-bottom: 4rem;
    }
}    

/* Desktop and tablet */
@media only screen and (min-width: 769px) {
    #offer {
        display: flex;
        flex-direction: column;
    }

    #offer p,
    #offer li {
        font-size: var(--font-size--base);
    }

    .material--wood,
    .material--tile,
    .material--tinware,
    .material--mug,
    .material--flashings,
    .material--windows {
        line-height: 2.8rem;
        width: 40rem;
    }

    .offer__tiles {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 156rem;
        grid-gap: 18rem;
        gap: 18rem;
        margin-bottom: 13rem;
    }
}
.realization__links {
  z-index: 1;
}

#realizations p {
  text-align: center;
}

.container p {
  padding-top: var(--space--xs);
  padding-bottom: var(--space--m);
}

.container {
  position: relative;
  border-radius: 1rem;
  background: #EEF2E6;
  color: var(--color--secondary);
  margin-bottom:var(--space--xxl);
  box-shadow: 10px 5px 5px #00000052;
}

.container img {
  width: 100%;
  height: auto;
  border-radius: 1rem 1rem 0rem 0rem;
  object-fit: cover;
}

.left--button {
  width: 5rem;
  height: 5rem;
  padding: 0;
  position: absolute;
  left: 0;
  z-index: 2;
}

.right--button {
  width: 5rem;
  height: 5rem;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}

.right--button svg {
  padding-left: 0.3rem;
}

.container div button {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  text-align: center;
}

button:active {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.background--realizations {
  background-color: #EEF2E6;
}

.background--realizations h1 {
  font-size: 2.2rem;
  background-color: #EEF2E6;
  color: var(--color--secondary);
  padding: var(--space--xxl);
}


/* Mobile */
@media only screen and (max-width: 768px) {
  #realizations {
    padding-top: var(--space--xxl);
    padding-bottom: 5rem;
    padding-left: var(--space--m);
    padding-right: var(--space--m);
  }

  .background--realizations h1 {
    font-size: 1.8rem;
    text-align: center;
  }

  .background--realizations p {
    padding-bottom: var(--space--s);
  }

  .background--realizations {
    padding: var(--space--s);
  }

  .realizations--images img {
    width: 100%;
    height: auto;
    border-radius: 1%;
  }
  
  .slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    background-color: var(--color--primary);
    padding-top: var(--space--xxl);
    padding-left: var(--space--m);
    padding-right: var(--space--m);
  }

  .container {
    width: 95%;
  }

  .slider__buttons {
    width: 100%;
  }

  .left--button,
  .right--button {
    top: 35%;
  }
}

/* Desktop and tablet */
@media only screen and (min-width: 769px) {
  #realizations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 81%;
    margin-bottom: 5rem;
    border-radius: 2rem;
  }

  .background--realizations p {
    padding: 0 var(--space--l) var(--space--l) ;
  }

  .realizations--images img {
    width: 50%;
    height: auto;
    border: 3px solid #EEF2E6;
    border-radius: 1%;
  }

  .slider {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    grid-gap:var(--space--xxl);
    gap:var(--space--xxl);
    max-width: 250rem;
    margin-bottom: 13rem;
    background-color: var(--color--primary);
    border-radius: 2rem;
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .container {
    width: 60rem;
    max-width: 60rem;
  }

  .slider__buttons {
    width: 60rem;
  }

  .left--button,
  .right--button {
    top: 14rem;
  }
  
}
#ff-compose{
    height:100%;
    margin:auto;
}

[class="btn btn-lg btn-primary"] {
    display: none;
}

#ff-success {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: var(--space--m);
}

#ff-success {
    font-size: 2.2rem;
}

.ff-form .ff-powered-img {
    width: 0.1rem !important;
    height: 0.1rem !important;
}

[src="https://formfacade.com/logo/madewith/formfacade.svg"],
.ff-partial {
    display: none !important;
}

.form-check-input {
    width: 5%;
}

.ff-description > div:nth-of-type(2) {
    display: none;
}

.ff-form,
.ff-required {
    color: var(--color--secondary)!important;
}

#Publish1FAIpQLSfVlF43KcO1mhpO0I9EZcau2S7NZqhLsxsGEtVV1SbbHUft_w {
    background-color: var(--color--primary)!important;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    #ff-compose{
        width:90%;
    }

    #Publish1FAIpQLSfVlF43KcO1mhpO0I9EZcau2S7NZqhLsxsGEtVV1SbbHUft_w {
        padding: var(--space--m);
    }
}
.footer {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--color--primary);
}

.localization,
.phone,
.email {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
}

.address--links {
    display: block;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .footer {
        flex-direction: row;
        justify-content: space-between;
        padding: var(--space--m);
    }

    .footer a {
        font-size: 1.2rem;
        padding-top: var(--space--m);
    }
    
    .footer h3 {
        font-size: 1.2rem;
    }

    .footer img {
        width: 3rem;
        height: 3rem;
        margin-right: var(--space--s);
    }

    .footer__section:first-of-type {
        display: block;
    }

    .footer__section:last-of-type {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
    }


    .localization,
    .email {
        padding-bottom: var(--space--m);
    }
}   


/* Desktop and tablet */
@media only screen and (min-width: 768px) {
    .footer {
        justify-content: space-around;
        flex-wrap: wrap;
        padding:var(--space--xl);;
    }

    .footer__section {
        display: contents;
    }

    .footer a {
        font-size: 2.4rem;
        padding-top: 3.5rem;
        padding-bottom:var(--space--xl);
    }

    .footer h3 {
        font-size: 2.4rem;
    }

    .footer img {
        margin-right: var(--space--m);
    }
}
