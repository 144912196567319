.footer {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--color--primary);
}

.localization,
.phone,
.email {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
}

.address--links {
    display: block;
}

/* Mobile */
@media only screen and (max-width: 768px) {
    .footer {
        flex-direction: row;
        justify-content: space-between;
        padding: var(--space--m);
    }

    .footer a {
        font-size: 1.2rem;
        padding-top: var(--space--m);
    }
    
    .footer h3 {
        font-size: 1.2rem;
    }

    .footer img {
        width: 3rem;
        height: 3rem;
        margin-right: var(--space--s);
    }

    .footer__section:first-of-type {
        display: block;
    }

    .footer__section:last-of-type {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
    }


    .localization,
    .email {
        padding-bottom: var(--space--m);
    }
}   


/* Desktop and tablet */
@media only screen and (min-width: 768px) {
    .footer {
        justify-content: space-around;
        flex-wrap: wrap;
        padding:var(--space--xl);;
    }

    .footer__section {
        display: contents;
    }

    .footer a {
        font-size: 2.4rem;
        padding-top: 3.5rem;
        padding-bottom:var(--space--xl);
    }

    .footer h3 {
        font-size: 2.4rem;
    }

    .footer img {
        margin-right: var(--space--m);
    }
}